<template>
  <div class="content content-main">
    <div class="w-100 mt-0">
      <div
        class="card no-border-card card-main mb-0"
        footer-classes="pb-2"
        v-loading.fullscreen.lock="loading"
      >
        <div class="course-selection-container">
          <div class="content">
            <div class="course-options">
              <div class="bg-none">
                <h1 class="title-main">Course Selection</h1>
                <p class="sub-line">
                  Build your Course load! Select Courses you want below, and
                  your Shopping Cart will update with the total Price.
                </p>
                <p class="unlock-course pointer" @click="openLightBoxPopup()">Unlock Significant Savings with Our Online
                  Courses</p>
              </div>
              <div
                class="d-flex align-items-center gap-3 justify-content-center my-5 py-4 flex-wrap"
              >
                <div>
                  <div
                    class="option-card"
                    :class="{
                      selected: selectedCourseType === 'bars/taverns/nightclubs' || selectedCourseType === undefined,
                    }"
                    @click="selectOption('bars/taverns/nightclubs')"
                  >
                    <div class="option-card-img">
                      <img
                        src="../../assets/mobile-assets.svg"
                        alt="bars/taverns/nightclubs"
                      />
                    </div>
                    <h2 class="option-card-title">
                      Training Classes for Bars (No Cooking)
                    </h2>
                    <!-- <p class="option-card-subLine">Security Training Only</p> -->
                  </div>
                </div>

                <div>
                  <div
                    class="option-card"
                    :class="{
                      selected:
                        selectedCourseType === 'all-other-establishments',
                    }"
                    @click="selectOption('all-other-establishments')"
                  >
                    <div class="option-card-img">
                      <img
                        src="../../assets/mobile-assets.svg"
                        alt="all-other-establishments"
                      />
                    </div>
                    <h2 class="option-card-title">
                      Training Classes for Bars (With Cooking)
                    </h2>
                    <!-- <p class="option-card-subLine">
                      Pick and choose for more flexibility.
                    </p> -->
                  </div>
                </div>
                <div>
                  <div
                    class="option-card"
                    :class="{
                      selected: selectedCourseType === 'restaurants',
                    }"
                    @click="selectOption('restaurants')"
                  >
                    <div class="option-card-img">
                      <img
                        src="../../assets/mobile-assets.svg"
                        alt="restaurants"
                      />
                    </div>
                    <h2 class="option-card-title">
                      Training Classes for Restaurants
                    </h2>
                    <!-- <p class="option-card-subLine">
                      Pick and choose for more flexibility.
                    </p> -->
                  </div>
                </div>
              </div>

              <div class="container px-0">
                <div class="tabs">
                  <button
                    class="tab-button"
                    :class="{ active: currentTab === 'Full Course' }"
                    @click="currentTab = 'Full Course'"
                  >
                    Full Course List
                  </button>
                  <!-- <button
                    class="tab-button"
                    :class="{ active: currentTab === 'Compliance' }"
                    @click="currentTab = 'Compliance'"
                  >
                    Compliance
                  </button>
                  <button
                    class="tab-button"
                    :class="{ active: currentTab === 'Safety' }"
                    @click="currentTab = 'Safety'"
                  >
                    Safety
                  </button> -->
                </div>

                <div v-show="currentTab === 'Full Course'" class="tab-content">
                  <!-- class="tab-content" -->
                  <div class="course-list">
                    <h3>Pre-selected Courses</h3>
                    <div
                      v-if="showLoader"
                      class="d-flex justify-content-center"
                    >
                      <i
                        class="text-success fas fa-spin fa-spinner p-4 mx-auto"
                      ></i>
                    </div>
                    <ul v-else class="course-list-ul">
                      <li
                        v-for="(course, index) in preSelectedCourses"
                        :key="course.id"
                      >
                        <!-- <input
                          type="checkbox"
                          :id="'preselected-course' + index"
                          :checked="selectedCourses.includes(course)"
                          @change="toggleCourse(course)"
                        /> -->
                        <input
                          type="checkbox"
                          :id="'preselected-course' + index"
                          :checked="isCourseSelected(course)"
                          :disabled="
                            preSelectedCourses.some((c) => c.id === course.id)
                          "
                        />
                        <label :for="'preselected-course' + index">
                          {{ course.name }}</label
                        >
                      </li>
                    </ul>
                    <hr class="hr-2" />
                    <div>
                      <div
                        class="d-flex justify-content-between align-items-center mt-4 flex-wrap px-0 w-100"
                      >
                        <div class="search-bar">
                          <input
                            type="text"
                            :placeholder="`Search ${currentTab}`"
                            class="search"
                            v-model="searchQuery"
                          />

                          <div class="search-icon">
                            <img
                              src="../../assets/search-icon.svg"
                              alt="search-icon.svg"
                            />
                          </div>
                        </div>
                        <div
                          class="select-all d-flex align-items-center gap-0 mt-3 mt-md-0"
                        >
                          <input
                            type="checkbox"
                            id="select-all"
                            v-model="selectAll"
                            @change="toggleSelectAll"
                          />
                          <label for="select-all">Select All</label>
                        </div>
                      </div>
                      <h3 class="mt-4">Additional Courses</h3>
                      <div
                        v-if="showLoader"
                        class="d-flex justify-content-center"
                      >
                        <i
                          class="text-success fas fa-spin fa-spinner p-4 mx-auto"
                        ></i>
                      </div>
                      <div v-else>
                        <ul class="course-list-ul">
                          <li
                            v-for="(course, index) in additionalCourses"
                            :key="course.id"
                          >
                            <input
                              type="checkbox"
                              :id="'additional-course' + index"
                              :checked="isCourseSelected(course)"
                              @change="toggleCourse(course)"
                            />
                            <label
                              :for="'additional-course' + index"
                              class="mb-0"
                            >
                              {{ course.name }}</label
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="currentTab === 'Compliance'" class="tab-content">
                  <div class="course-list">
                    <!-- <p>Compliance content goes here.</p> -->
                    <div
                      v-if="showLoader"
                      class="d-flex justify-content-center"
                    >
                      <i
                        class="text-success fas fa-spin fa-spinner p-4 mx-auto"
                      ></i>
                    </div>
                    <ul v-else class="course-list-ul">
                      <li v-for="(course, index) in courses" :key="course.id">
                        <input
                          type="checkbox"
                          :id="'course-compliance' + index"
                          :checked="selectedCourses.includes(course)"
                          @change="toggleCourse(course)"
                        />
                        <label :for="'course-compliance' + index">
                          {{ course.name }}</label
                        >
                      </li>
                    </ul>
                  </div>
                </div>

                <div v-show="currentTab === 'Safety'" class="tab-content">
                  <div class="course-list">
                    <!-- <p>Safety content goes here.</p> -->
                    <div
                      v-if="showLoader"
                      class="d-flex justify-content-center"
                    >
                      <i
                        class="text-success fas fa-spin fa-spinner p-4 mx-auto"
                      ></i>
                    </div>
                    <ul v-else class="course-list-ul">
                      <li v-for="(course, index) in courses" :key="course.id">
                        <input
                          type="checkbox"
                          :id="'course-safety' + index"
                          :checked="selectedCourses.includes(course)"
                          @change="toggleCourse(course)"
                        />
                        <label :for="'course-safety' + index">
                          {{ course.name }}</label
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="summary-section">
              <div class="cart-section">
                <div class="d-flex justify-content-between">
                  <h3>
                    <!-- {{ selectedCourses.length > 0 ? "Course" : "Courses"  }} -->
                    {{ `${selectedCourses.length} Courses Added to Cart` }}
                  </h3>
                  <div class="bsaket-img">
                    <img src="../../assets/basket-icon.svg" alt="Basket icon" />

                    <div class="red-dot">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="6"
                          cy="6"
                          r="5"
                          fill="#C02E1C"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <!-- <p class="total-cost d-flex justify-content-between">
                  This is for test => Total Price TODO:
                  <strong>${{ getTotalPrice() }}</strong>
                </p> -->
                <!-- <p class="total-cost d-flex justify-content-between">
                  Course Discount Price:
                  <strong class="text-right" v-if="!promocodeApplied"
                    >${{ getPriceAfterDiscount() }}</strong
                  >
                  <strong v-else
                    ><span style="text-decoration: line-through">
                      ${{ getPriceAfterDiscount() }}</span
                    >
                    ${{
                    (getPriceAfterDiscount() - (getPriceAfterDiscount() * (discountPercentage / 100))).toFixed(2)

                    }}</strong
                  >
                </p> -->
                <p class="total-cost d-flex justify-content-between">
                  Total cost per month:
                  <strong class="text-right"
                    >
                    <!-- ${{ getPriceAfterDiscountPerMonth() }} -->
                    ${{ formatValue(getPriceAfterDiscountPerMonth()) }}
                    </strong
                  >
                </p>
                <p class="d-flex justify-content-between">
                  Price if paid in full for the year (10% off):
                  <strong class="text-right">
                    <!-- <span
                      v-if="selectedCourses.length > 0"
                      style="text-decoration: line-through"
                      >${{ getPriceAfterDiscount() }}</span
                    > -->
                    ${{
                      formatValue( getCoursePricePerYearAfter10PercentageDiscount())
                    }}</strong
                  >
                </p>
                <p class="d-flex justify-content-between">
                  Monthly cost per location:
                  <strong class="text-right"
                    >${{ formatValue(this.getCostPerLocation()) }}</strong
                  >
                </p>
                <p class="d-flex justify-content-between">
                  Monthly cost per user:
                  <strong>${{ formatValue(getCostPerUser()) }}</strong>
                </p>

                <p
                  class="d-flex justify-content-between"
                  v-for="(addon, index) in selectedAddOns"
                  :key="addon.id"
                >
                  {{ addon.title }}
                  {{
                    addon.is_additional === 0 && addon.durations === "monthly"
                      ? "per month"
                      : addon.durations === "per year"
                      ? "year"
                      : addon.durations === "peruser"
                      ? "per user"
                      : ""
                  }}

                  <strong v-if="addon.is_additional === 0"
                    >${{ addon.price }}</strong
                  >
                  <strong v-else>$0.00</strong>
                </p>
                <div class="promo-section">
                  <input
                    class="input-main"
                    placeholder="Promotional Code"
                    :rules="{ required: true }"
                    v-model="promoCode"
                  />

                  <button
                    class="apply-btn"
                    type="primary"
                    @click.prevent="applyPromoCode"
                  >
                    Apply
                  </button>
                </div>

                <p v-if="promocodeApplied" :style="{ color: '#088206' }">
                  {{
                    `Promocode applied successfully of ${discountPercentage}%.`
                  }}
                  <!-- {{
                    `Amount left after applying a ${discountPercentage}% discount: $${amountLeftAfterDiscountToPayInstance} amount of ${getPriceAfterDiscount()}`
                  }}

                  {{
                    `Amount left after applying a ${discountPercentage}% discount: $${amountLeftAfterDiscountToPay} amount of ${getCoursePricePerYear()}`
                  }} -->
                </p>
                <p v-else class="error-message" :style="{ color: '#a02518' }">
                  {{ promocodeErrorMessage }}
                </p>
                <p v-if="!promocodeApplied">
                  Use code <strong>HIG</strong> to get
                  {{ discountPercentage ? discountPercentage + "%" : "" }}
                  discount
                </p>
              </div>

              <hr class="my-5" />
              <div class="addons-container">
                <h4 class="addons-title">Select Add-Ons</h4>
                <div
                  v-if="showAddonsLoader"
                  class="d-flex justify-content-center"
                >
                  <i
                    class="text-success fas fa-spin fa-spinner p-4 mx-auto"
                  ></i>
                </div>
                <div v-else class="addons-box">
                  <div
                    class="addon-item"
                    v-for="(addon, index) in addOns"
                    :key="addon.id"
                  >
                    <div class="addon-info">
                      <div>
                        <img
                          :src="addon.icon"
                          alt="addon icon"
                          class="addon-icon"
                        />
                      </div>
                      <label :for="'addon' + index" class="label">
                        {{ addon.title }} (${{ addon.price }} per
                        {{
                          addon.durations === "monthly"
                            ? "month"
                            : addon.durations === "year"
                            ? "year"
                            : addon.durations === "peruser"
                            ? "user"
                            : ""
                        }})</label
                      >
                      <div class="info-icon">
                        <el-tooltip
                          :content="addon.descriptions"
                          placement="top"
                          v-if="addon.descriptions"
                        >
                          <img
                            src="../../assets/svgviewer-output.svg"
                            alt="info icon"
                          />
                        </el-tooltip>
                      </div>
                    </div>
                    <input
                      type="checkbox"
                      :id="'addon' + index"
                      :checked="selectedAddOns.includes(addon)"
                      @change="toggleAddOn(addon)"
                    />
                  </div>
                </div>
              </div>
              <div class="addons-container mt-3">
                <h4 class="addons-title">Call for more information</h4>
                <div
                  v-if="showAddonsLoader"
                  class="d-flex justify-content-center"
                >
                  <i
                    class="text-success fas fa-spin fa-spinner p-4 mx-auto"
                  ></i>
                </div>
                <div v-else class="addons-box">
                  <div
                    class="addon-item"
                    v-for="(addon, index) in additionalAddons"
                    :key="addon.id"
                  >
                    <div class="addon-info">
                      <div>
                        <img
                          :src="addon.icon"
                          alt="addon icon"
                          class="addon-icon"
                        />
                      </div>
                      <label :for="'additional-addon' + index" class="label">
                        {{ addon.title }}
                      </label>
                    </div>
                    <!-- <input
                      type="checkbox"
                      :id="'additional-addon' + index"
                      :checked="selectedAddOns.includes(addon)"
                      @change="toggleAddOn(addon)"
                    /> -->
                  </div>
                </div>
              </div>
              <div class="action-buttons">
                <button class="btn-2 btn-back" @click="goBack">
                  <svg
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                  >
                    <path
                      d="M1.441 6.477 7.066.852a1.084 1.084 0 0 1 1.582 0c.457.421.457 1.16 0 1.582L3.832 7.25l4.816 4.852c.457.421.457 1.16 0 1.582a1.084 1.084 0 0 1-1.582 0L1.441 8.059a1.084 1.084 0 0 1 0-1.582"
                      fill="#010101"
                    />
                  </svg>
                  Back
                </button>
                <button class="btn-2 btn-signup" @click="showAdministartorPopup()">
                  Sign Up
                </button>
              </div>

              <div class="contact-info">
                <p class="simpel-text mb-0">
                  If you have any questions, please contact us at
                </p>
                <p class="link-text">
                  <a class="mr-3" href="mailto:info@homeoftraining.com">
                    <span>
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5.5h13c.813 0 1.5.688 1.5 1.5 0 .5-.25.938-.625 1.219L8.594 8.313a.986.986 0 0 1-1.219 0L.594 3.218A1.5 1.5 0 0 1 0 2 1.5 1.5 0 0 1 1.5.5M0 4l6.781 5.125a2.02 2.02 0 0 0 2.407 0L16 4v6.5c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2z"
                          fill="#00539B"
                        />
                      </svg>
                    </span>
                    info@homeoftraining.com
                  </a>
                  |
                  <span>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m5.125 1.281 1.25 3c.219.5.094 1.094-.344 1.438L4.5 7a10.5 10.5 0 0 0 5 5l1.281-1.531a1.206 1.206 0 0 1 1.438-.344l3 1.25c.594.219.875.875.719 1.469l-.75 2.75A1.24 1.24 0 0 1 14 16.5c-7.75 0-14-6.25-14-14 0-.562.375-1.031.906-1.187l2.75-.75c.594-.157 1.25.125 1.469.718"
                        fill="#00539B"
                      />
                    </svg>
                  </span>
                  954-676-7900
                </p>
                <p class="link-text pointer" @click="openFaqsPopup()">FAQs</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about-assets-img">
        <img src="../../assets/about-10.png" alt="about-10.png" />
      </div>
      <!-- <div class="faqsboday"> -->

      <!-- FAQs Modal -->
      <modal :show.sync="showFAQsModal">
        <h3 slot="header" class="mb-0 selectionfaq">
          Frequently asked questions
        </h3>
        <div v-if="showAddonsLoader" class="d-flex justify-content-center">
          <i class="text-success fas fa-spin fa-spinner p-4 mx-auto"></i>
        </div>
        <div v-else class="faqsboday">
          <ul class="pl-0" v-for="(faq, index) in faqsList" :key="faq.id">
            <li class="faqsqusetion">{{ faq.question }}</li>
            <li class="faqsanser">{{ faq.answer }}</li>
          </ul>
        </div>
      </modal>

     <!-- Administartor Modal -->
      <modal :show.sync="showAdministratorModal">
        <h3 slot="header"  style="color: #444C57" class=" mt-4 ml-2">
                      Administrator Login Information
                    </h3>
                  <div class="form-row">
                    <div class="col-md-4">
                      <label class="form-control-label"
                        >First Name <span class="req"> *</span></label
                      >
                      <base-input
                        type="text"
                        name="First Name"
                        placeholder="First Name"
                        rules="required"
                        v-model="company.first_name"
                      >
                      </base-input>
                    </div>
                    <div class="col-md-4">
                      <label class="form-control-label"
                        >Last Name <span class="req"> *</span></label
                      >
                      <base-input
                        type="text"
                        name="Last Name"
                        placeholder="Last Name"
                        rules="required"
                        v-model="company.last_name"
                      >
                      </base-input>
                    </div>
                    <div class="col-md-4">
                      <label class="form-control-label"
                        >Phone <span class="req"> *</span></label
                      >
                      <base-input
                        name="Phone Number"
                        placeholder="Phone"
                        rules="required"
                        v-model="company.phone_num"
                        @input="acceptNumber"
                      >
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label"
                        >Email Address <span class="req"> *</span></label
                      >
                      <base-input
                        type="email"
                        name="Email Address"
                        placeholder="Email Address"
                        rules="required"
                        v-model="company.email"
                      >
                      </base-input>
                    </div>

                    <div class="col-md-6">
                      <label class="form-control-label"
                        >Password <span class="req"> *</span></label
                      >
                      <!-- <base-input
                        :type="passwordFieldType"
                        v-if="!(company_id !== '')"
                        name="Password"
                        placeholder="Password"
                        rules="required"
                        v-model="company.password"
                      >
                      </base-input> -->
                      <div class="d-flex align-items-center gap-2">
                      <base-input
                        :type="passwordFieldType"
                        name="Password"
                        placeholder="Password"
                        rules="required"
                        v-model="company.password"
                        class="w-100 mr-3"
                      >
                      </base-input>
                      <div class="password-eye mb-3">
                      <span @click.prevent="switchVisibility"
                        ><i class="fa fa-eye" title="Show Password"></i
                      ></span>
                      </div>
                    </div>
                    </div>
                    <!-- <div
                      class="col-md-1 password-eye"
                      style="margin-top: 40px;"
                    >
                      <span @click.prevent="switchVisibility"
                        ><i class="fa fa-eye" title="Show Password"></i
                      ></span>
                    </div> -->
                  </div>
                  <div class="form-row">
                    <h3>AUTHORIZATION</h3>
                    <p>
                      <small>
                        By clicking the "Create Account" button below, you agree
                        that the name you typed in the box above (Your Full
                        Name) will be the electronic representation of your
                        signature for all purposes in relation to the
                        {{ siteName }}, LLC legally binding Service Activation
                        Agreement. You agree that your electronic signature is
                        considered an original, for purposes of entering into a
                        contract.
                      </small>
                    </p>
                  </div>
                  <div class="d-flex justify-content-center">
                    <!-- :disabled="isContinueButtonDisabled" -->
                    <button type="submit" class=" mt-4 btn-2 btn-signup" @click="showPaymentGateway">
                      Continue
                    </button>
                  </div>
      </modal>

      <!-- Payment Modal -->
      <modal :show.sync="showPaymentModal">
        <h4
          slot="header"
          style="color: #444c57"
          class="title title-up text-center"
        >
          Payment
        </h4>

        <credit-card
          :companyName="companyName"
          :monthlyAmount="Number(getPriceAfterDiscountPerMonth())"
          :yearlyAmount="
            Number(getCoursePricePerYearAfter10PercentageDiscount())
          "
          :address="companyAddress"
          :stateId="stateId"
          :enablePaymentButton="enablePaymentButton"
          @payClicked="signUp"
        />
      </modal>

       <!-- showLighBoxModal Modal -->
     <modal :show.sync="showLighBoxModal" >
        <h2 slot="header" class="header-title text-ml font-bold">
          Unlock Significant Savings with Our Online
        Courses
        </h2>
        <p class="header-text">
        Complete all assigned courses and enjoy up to a 10% discount on your insurance
        premium. That's not all - you'll also benefit from reduced accidents, lower employee
        turnover, and streamlined training processes.</p>
        <h3 class="header-h3">
          See the Proof: One of Home of Training’s clients, a national restaurant chain with 400+ locations, reduced their dramshop liability claims by 95% within a year after implementing a training program. This significant decrease translated directly into substantial cost savings and improved legal compliance.
        </h3>
      </modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreditCard from "./HIGCreditCard.vue";
import {Dynamic} from "../../wl";

export default {
  name: "CourseSelection",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CreditCard,
  },
  data() {
    return {
      barsNoCookingCourses: [115, 78, 80, 77],
      barsCookingCourses: [115, 31, 57, 59, 60, 61, 280, 260, 77, 78, 80, 283],
      restaurantCourses: [31, 57, 59, 60, 61, 280, 260, 77, 78, 80, 283],
      loading: false,
      activeTab: "full",
      search: "",
      promoCode: "",
      discountPercentage: 0,
      // discountAmount: 0,
      amountLeftAfterDiscountToPay: 0,
      amountLeftAfterDiscountToPayInstance: 0,
      numberOfLocations:
        Number(
          JSON.parse(localStorage.getItem("hospitality-insurance"))
            .number_of_locations
        ) || 0,
      usersPerLocation:
        Number(
          JSON.parse(localStorage.getItem("hospitality-insurance"))
            .number_of_employees
        ) || 0,

      companyAddress:
        JSON.parse(localStorage.getItem("hospitality-insurance")).address || "",
      stateId:
        JSON.parse(localStorage.getItem("hospitality-insurance")).state_id ||
        null,
      zipCode:
        JSON.parse(localStorage.getItem("hospitality-insurance")).zipCode ||
        null,
      companyName:
        JSON.parse(localStorage.getItem("hospitality-insurance"))
          .company_name || "",
          company: {
                first_name: "",
                last_name: "",
                // company_type: "",
                // parent_company: "",
                // name: "",
                administrator: "",
                // no_of_locations: "",
                // no_of_employees: "",
                // address_1: "",
                // address_2: "",
                // city: "",
                // state: "",
                // zip: "",
                // logo: "",
                phone_num: "",
                email: "",
                password: ""
            },
      showFAQsModal: false,
      showLighBoxModal: false,
      selectedAddOns: [],
      selectAll: false,
      showLoader: false,
      showAddonsLoader: false,
      showPaymentModal: false,
      showAdministratorModal: false,
      isContinueButtonDisabled: false,
      selectedCourses: [],
      defaultSelectedCourses: [
        115, 78, 80, 77,
      ],
      courses: [],
      preSelectedCourses: [],
      additionalCourses: [],
      userWiseDiscountRules: [],
      searchQuery: "",
      addOns: [],
      additionalAddons: [],
      faqsList: [],
      showFAQsLoader: false,
      currentTab: "Full Course",
      // selectedCourseType: "bars/taverns/nightclubs",
      selectedCourseType: "bars/taverns/nightclubs",
      passwordFieldType: "password",
      promocodeErrorMessage: "",
      promocodeApplied: false,
      enablePaymentButton: false,
      siteName: "",
      infoEmail: "",

      rules: {
        promoCode: [
          {
            required: true,
            message: "Please input promo code",
            trigger: "blur",
          },
        ],
      },
    };
  },
  async mounted() {
    // get full course, compliances & safety
    await this.getFullCourseComplianceSafety();
    await this.getAddonsList();
    let insuranceInstance = JSON.parse(
      localStorage.getItem("hospitality-insurance")
    );
    if (insuranceInstance) {
      (this.promoCode = insuranceInstance?.promo_code),
        (this.selectedCourseType = insuranceInstance?.selected_course_type);
      this.selectedCourses = insuranceInstance?.special_courses.map((item) =>
        this.courses.find((course) => course.id === item)
      );
      this.selectedAddOns = insuranceInstance?.add_on.map((item) =>
        this.addOns.find((addOn) => addOn.id === item)
      );
      // this.numberOfLocations = Number(insuranceInstance.number_of_locations)
      // this.usersPerLocation = Number(insuranceInstance.number_of_employees)
      // this.numberOfLocations = 5
      // this.usersPerLocation = 20

      await this.updateInsuranceInstance(insuranceInstance);
    }

    this.siteName = Dynamic.SITE_NAME;
    this.infoEmail = Dynamic.INFO_EMAIL;
  },
  computed: {
    filteredCourses() {
      if (!this.search) return this.courses;
      return this.courses.filter((course) =>
        course.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  watch: {
    selectedCourses: {
      handler(newVal) {
        this.selectAll = newVal.length === this.courses.length;
      },
      deep: true,
    },
    currentTab(newTab) {
      if (newTab === "Compliance") {
        this.getFullCourseComplianceSafety(1);
        // this.selectedCourses = [];
      } else if (newTab === "Safety") {
        this.getFullCourseComplianceSafety(0);
        // this.selectedCourses = [];
      } else {
        this.getFullCourseComplianceSafety();
        // this.selectedCourses = [];
      }
    },
    searchQuery: "getFullCourseComplianceSafety",
  },
  methods: {
    formatValue(value) {
      if (!value) return ''; // Return empty if no value is input

      // Convert the value to a number
      let numberValue = Number(value.replace(/[^\d.-]/g, '')); // Removes any non-numeric characters
      if (isNaN(numberValue)) return value; // If the input is not a number, return it unchanged

      // Format the number to the international format (with commas)
      return numberValue.toLocaleString('en-US');
    },

    openLightBoxPopup() {
      this.showLighBoxModal = true;
    },

     openFaqsPopup() {
      this.showFAQsModal = true;
      this.getFaqsList();
    },
    async getFaqsList() {
      this.showFAQsLoader = true;
      await this.$http
        .get("/course/faqs")
        .then((resp) => {
          this.faqsList = resp.data;
          this.showFAQsLoader = false;
        })
        .catch(function (error) {
          console.log(error);
          this.showFAQsLoader = false;
        });
    },
    async getAddonsList() {
      this.showAddonsLoader = true;

      await this.$http
        .get("/addon_add/list", {
          params: {
            status: "Active",
          },
        })
        .then((resp) => {
          let addOnsInstance = resp.data.data;
          this.addOns = addOnsInstance.filter(
            (addon) => addon.is_additional === 0
          );
          this.additionalAddons = addOnsInstance.filter(
            (addon) => addon.is_additional === 1
          );
          // this.addOns = resp.data.data;
          this.showAddonsLoader = false;
        })
        .catch(function (error) {
          console.log(error);
          this.showAddonsLoader = false;
        });
    },

    getCoursesTotalPrice() {
      const totalRetailPrice = this.selectedCourses
        .reduce((total, course) => {
          if (course && course.cost) {
            return total + parseFloat(course.cost);
          } else {
            return total;
          }
        }, 0)
        .toFixed(2);
      return totalRetailPrice;
    },

    // Method to calculate the total number of users
    getTotalUsers() {
      return Number(this.numberOfLocations) * Number(this.usersPerLocation);
    },

    // Method to calculate the total price for all users
    getTotalPrice() {
      const totalUsers = this.getTotalUsers();
      const totalRetailPrice = this.getCoursesTotalPrice();
      return totalUsers * totalRetailPrice;
    },

    // Method to get the discount percentage based on the number of users
    getDiscountPercentage() {
      const totalUsers = this.getTotalUsers();

      let discountRule = this.userWiseDiscountRules.find((rule) => {
        return totalUsers >= rule.from && totalUsers <= rule.to;
      });

      if (discountRule) {
        return Number(discountRule.value);
      } else {
        return 0; // No discount for users more than 201
      }
    },

    getSelectedAddonsSum() {
      let sumOfAddons;
      if (this.selectedAddOns.length > 0) {
        sumOfAddons = this.selectedAddOns
          .filter((addon) => addon.is_additional === 0)
          .reduce((total, addon) => {
            let perUserCharges = 0;
            if (addon.durations === "peruser") {
              //  2.5 = 0.25 * 10
              perUserCharges = parseFloat(addon.price) * this.getTotalUsers();
              // console.log("perUserCharges 1>>>", perUserCharges)
              // console.log("perUserCharges2>>>", parseFloat(addon.price))
              return total + perUserCharges;
            }
            return (
              total + parseFloat(addon.price) * Number(this.numberOfLocations)
            );
          }, 0);
      } else {
        sumOfAddons = 0;
      }

      return Number(sumOfAddons);
    },
    // Method to calculate the price after discount
    getPriceAfterDiscount() {
      const totalPrice = this.getTotalPrice();
      const discountPercentage = this.getDiscountPercentage();

      const discountAmount = (totalPrice * discountPercentage) / 100;

      return (
        totalPrice -
        discountAmount +
        parseFloat(this.getSelectedAddonsSum())
      ).toFixed(2);
    },

    getPriceAfterDiscountPerMonth() {
      const totalPrice = this.getTotalPrice();
      const discountPercentageNumberOfUsers = this.getDiscountPercentage();
      let discountedAmount =
        (totalPrice * discountPercentageNumberOfUsers) / 100;
      let totalPriceWithAddons;
      let afterMinusDiscounteAmount = totalPrice - discountedAmount;
      if (this.promocodeApplied) {
        totalPriceWithAddons = afterMinusDiscounteAmount / 12;
        let discountedPriceAfterApplyingPromocode =
          totalPriceWithAddons * (this.discountPercentage / 100);
        let priseAfterDiscountWitPromocode =
          totalPriceWithAddons - discountedPriceAfterApplyingPromocode;
        return (
          priseAfterDiscountWitPromocode + this.getSelectedAddonsSum()
        ).toFixed(2);
      } else {
        totalPriceWithAddons =
          afterMinusDiscounteAmount / 12 + this.getSelectedAddonsSum();
        return totalPriceWithAddons.toFixed(2);
      }
    },

    // Method to calculate the monthly cost per location
    getCostPerLocation() {
      const priceAfterDiscount = this.getPriceAfterDiscountPerMonth();
      return (priceAfterDiscount / this.numberOfLocations).toFixed(2);
    },

    // Method to calculate the cost per user
    getCostPerUser() {
      let priceAfterDiscount = this.getPriceAfterDiscountPerMonth();
      return (priceAfterDiscount / this.getTotalUsers()).toFixed(2);
    },

    // Method to calculate the annual cost per location with a 10% discount for yearly payment
    getCoursePricePerYear() {
      let coursePricePerYear = this.getPriceAfterDiscountPerMonth() * 12;
      return coursePricePerYear.toFixed(2);
    },
    getCoursePricePerYearAfter10PercentageDiscount() {
      let priceAfterDiscountAmountofTenPercentage =
        this.getCoursePricePerYear() * 0.1;
      const costPerYearAfterApplying10Discount =
        this.getCoursePricePerYear() - priceAfterDiscountAmountofTenPercentage;
      return costPerYearAfterApplying10Discount.toFixed(2);
    },
    async updateInsuranceInstance(insuranceInstance) {
      insuranceInstance.course_cost = this.getCoursesTotalPrice();
      insuranceInstance.total_cost_per_year = this.getCoursePricePerYear();
      insuranceInstance.per_location = this.getCostPerLocation();
      insuranceInstance.per_user = this.getCostPerUser();
    },
    selectOption(option) {
      // Handle option selection logic here
      this.selectedCourseType = option;
      this.getFullCourseComplianceSafety();
    },
    // toggleSelectAll() {
    //   if (this.selectAll) {
    //     this.selectedCourses = [...this.courses];
    //   } else {
    //     this.selectedCourses = [];
    //   }
    // },
    toggleSelectAll() {
      if (this.selectAll) {
        this.selectedCourses = [
          ...this.preSelectedCourses,
          ...this.additionalCourses.filter(
            (course) =>
              !this.preSelectedCourses.some(
                (preSelectedCourse) => preSelectedCourse.id === course.id
              )
          ),
        ];
      } else {
        this.selectedCourses = [...this.preSelectedCourses];
      }
    },
    // toggleCourse(course) {
    //   const courseIndex = this.selectedCourses.findIndex(
    //     (c) => c?.id === course?.id
    //   );

    //   if (courseIndex !== -1) {
    //     // Course is already selected, remove it from the array
    //     this.selectedCourses.splice(courseIndex, 1);
    //   } else {
    //     // Course is not selected, add it to the array
    //     this.selectedCourses = [
    //       ...this.selectedCourses.filter((c) => c.id !== course.id),
    //       course,
    //     ];
    //   }
    //   console.log("this.selectedCourses>>>>", this.selectedCourses);
    // },
    toggleCourse(course) {
      if (
        this.preSelectedCourses.some(
          (preSelectedCourse) => preSelectedCourse.id === course.id
        )
      ) {
        return; // Prevent unchecking preselected courses
      }
      const courseIndex = this.selectedCourses.findIndex(
        (c) => c?.id === course?.id
      );

      if (courseIndex !== -1) {
        // Course is already selected, remove it from the array
        this.selectedCourses.splice(courseIndex, 1);
      } else {
        // Course is not selected, add it to the array
        this.selectedCourses = [
          ...this.selectedCourses.filter((c) => c.id !== course.id),
          course,
        ];
      }
    },
    isCourseSelected(course) {
      return this.selectedCourses.some((selected) => selected.id === course.id);
    },

    toggleAddOn(addOn) {
      const addOnIndex = this.selectedAddOns.findIndex(
        (c) => c?.id === addOn?.id
      );

      if (addOnIndex !== -1) {
        // AddOn is already selected, remove it from the array
        this.selectedAddOns.splice(addOnIndex, 1);
      } else {
        // AddOn is not selected, add it to the array
        this.selectedAddOns = [
          ...this.selectedAddOns.filter((c) => c.id !== addOn.id),
          addOn,
        ];
      }
    },
    async getFullCourseComplianceSafety(courseType) {
       // Use this.selectedCourseType directly to set defaultSelectedCourses
       switch (this.selectedCourseType) {
        case "bars/taverns/nightclubs":
          this.defaultSelectedCourses = this.barsNoCookingCourses;
          break;
        case "restaurants":
          this.defaultSelectedCourses = this.restaurantCourses;
          break;
        case "all-other-establishments":
          this.defaultSelectedCourses = this.barsCookingCourses;
          break;
        default:
          this.defaultSelectedCourses = this.barsNoCookingCourses; // Default to bars no cooking if undefined
          break;
      }
      let params;
      if (courseType === 0 || courseType === 1) {
        params = {
          search: this.searchQuery,
          course_type: courseType,
        };
      } else {
        params = {
          search: this.searchQuery,
        };
      }
      this.showLoader = true;
      await this.$http
        .get("/user/discountRules", { params })
        .then((resp) => {
          this.courses = resp?.data?.courses;
          this.selectedCourses = this.courses.filter((course) =>
            this.defaultSelectedCourses.includes(course.id)
          );
          this.preSelectedCourses = this.courses.filter((course) =>
            this.defaultSelectedCourses.includes(course.id)
          );
          this.additionalCourses = this.courses.filter(
            (course) => !this.defaultSelectedCourses.includes(course.id)
          );
          this.userWiseDiscountRules = resp?.data?.userwise_discount_rules;

          this.showLoader = false;
        })
        .catch(function (error) {
          console.log(error);
          this.showLoader = false;
        });
    },
    applyPromoCode() {
      let promocodePayload = {
        promocode: this.promoCode,
      };
      this.$http
        .post("/promocode/apply_promocode", promocodePayload)
        .then((resp) => {
          this.discountPercentage = Number(resp.data?.discount_percentage);
          if (this.discountPercentage) this.promocodeApplied = true;
        })
        .catch((error) => {
          this.promocodeErrorMessage = error.response.data.message;
          this.promocodeApplied = false;
        });
      // Handle promo code application logic here
    },
    switchVisibility() {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password";
        },
        acceptNumber() {
            var x = this.company.phone_num
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.phone_num = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

            var y = this.contact.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.contact.phone = !y[2]
                ? y[1]
                : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
        },
    goBack() {
      // Handle back button logic here
      // this.$router.push("/hospitality-insurance");
      const companyName = this.$route.params.companyName;
      this.$router.push(`/hig/${companyName}?`);
    },
    showAdministartorPopup() {
      if(this.selectedCourseType===undefined)
      {
        this.selectedCourseType ="bars/taverns/nightclubs";
      }
      if (!this.selectedCourseType) {
        return Swal.fire({
          title: "Error!",
          text: "Please select course type. like Training classes for Bars/Taverns/Nightclubs, Training classes for Restaurants or Training classes.",
          icon: "error",
        });
      }
      this.showAdministratorModal = true;
      this.fetchedAdministratorPopupDetail();
    },
    showPaymentGateway() {
      if( this.company.first_name == "" || this.company.last_name == "" || this.company.phone_num == "" || this.company.email == "" || this.company.password == "") {
        return Swal.fire({
          title: "Error!",
          text: "Please fill all the required fields.",
          icon: "error",
        });
      }
      this.showAdministratorModal = false;
      this.showPaymentModal = true;
      this.enablePaymentButton = false;
    },
   fetchedAdministratorPopupDetail() {
    let insurancePayload =
    JSON.parse(localStorage.getItem("hospitality-insurance")) || {};
    if(insurancePayload) {
      this.company.first_name = insurancePayload?.first_name || "";
      this.company.last_name = insurancePayload?.last_name || "";
      this.company.phone_num = insurancePayload?.phone_num || "";
      this.company.email = insurancePayload?.email || ""
    }
   },
   async signUp(cardData) {
      let insurancePayload =
        JSON.parse(localStorage.getItem("hospitality-insurance")) || {};
      // Handle sign up logic here
      let selectedCoursesIds = this.selectedCourses
        .filter((course) => course !== undefined && course !== null)
        .map((course) => course.id);

      let addOnIds = this.selectedAddOns.map((addOn) => addOn.id);

      // Administartors Detail
      (insurancePayload.first_name = this.company.first_name),
      (insurancePayload.last_name = this.company.last_name),
      (insurancePayload.phone_num = this.company.phone_num),
      (insurancePayload.email = this.company.email),
      (insurancePayload.password = this.company.password),
      // Course selection detail
      (insurancePayload.special_courses = []),
        (insurancePayload.promo_code = this.promoCode),
        (insurancePayload.course_cost = this.getCoursesTotalPrice()),
        (insurancePayload.discounted_cost = this.promocodeApplied
          ? (
              this.getPriceAfterDiscount() -
              this.getPriceAfterDiscount() * (this.discountPercentage / 100)
            ).toFixed(2)
          : this.getPriceAfterDiscountPerMonth()),
        (insurancePayload.cost_per_month =
          this.getPriceAfterDiscountPerMonth()),
        (insurancePayload.total_cost_per_year = this.getCoursePricePerYear()), // TODO: change this
        (insurancePayload.per_location = this.getCostPerLocation()),
        (insurancePayload.per_user = this.getCostPerUser()),
        (insurancePayload.addons_ids = addOnIds),
        (insurancePayload.selected_course_type = this.selectedCourseType),
        (insurancePayload.user_type = "corporate"),
        (insurancePayload.course_ids = selectedCoursesIds)

      // console.log("Received card data:", cardData); // Add this line for debugging

      let payment = {
        payment_type: cardData.paymentType === "monthly" ? 1 : 2,
        cardholder_street_address: cardData.address,
        // cardholder_zip: cardData.zip,
        token: cardData.token,
        transaction_amount:
          cardData.paymentType === "monthly"
            ? this.getPriceAfterDiscountPerMonth()
            : this.getCoursePricePerYear(),
      };
      insurancePayload.payment = payment;
      // console.log("insurancePayload Payload ===>>>", insurancePayload);
      // return;
      localStorage.setItem(
        "hospitality-insurance",
        JSON.stringify(insurancePayload)
      );

      await this.$http
        .post("user/hig-lead", insurancePayload)
        .then((resp) => {
          Swal.fire({
            title: "Success!",
            text: `Account has been Created!`,
            icon: "success",
          });
          localStorage.removeItem("hospitality-insurance");
          setTimeout(() => {
            // const companyName = this.$route.params.companyName;
            this.$router.push(`/new_registration`);
          }, 1500);
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            Swal.fire({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
            });
          }
          // this.enablePaymentButton = false
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");

/* ---- Custom Scrollbar Styles starts ---- */
.course-list-ul {
  max-height: 300px; /* Adjust based on your needs */
  /* over-flow-y:auto; */
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.pointer {
  cursor: pointer;
}
/* Webkit Browsers (Chrome, Safari) */
.course-list-ul::-webkit-scrollbar,
.faqsboday::-webkit-scrollbar {
  width: 3px;
  height: 95px;
}

.course-list-ul::-webkit-scrollbar-track,
.faqsboday::-webkit-scrollbar-track {
  background: #ffffff; /* Background color of the scrollbar track */
}

.course-list-ul::-webkit-scrollbar-thumb,
.faqsboday::-webkit-scrollbar-track {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

.course-list-ul::-webkit-scrollbar-thumb:hover,
.faqsboday::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}

/* Firefox */
/* .course-list-ul {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
} */
/* ------ Scroll bar css ends------ */
.course-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
}
.header {
  text-align: center;
  margin-bottom: 30px;
}
.content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 40px;
}
/* .content-main {
  padding: 100px 50px 50px;
} */
.card-main {
  position: relative;
}
.about-assets-img {
  position: absolute;
  width: 144px;
  height: 174px;
  bottom: 0;
  left: 0;
}
.about-assets-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-options {
  /* flex: 1 1; */
  padding: 20px 48px;
  max-width: 834px;
}
.summary-section {
  /* flex: 0 1; */
  padding: 20px;
  margin: auto;
}
.course-options {
  border-right: 1px solid #dddddd;
}
.gap-3 {
  gap: 20px;
}
.faqsboday {
  height: 500px !important;
  overflow-y: auto;
}

.selectionfaq {
  font-family: "Red Hat Display", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 39.69px;
  color: #010101;
}
.faqsqusetion {
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 23.81px;
  text-align: left;
  color: #010101;
  list-style: none;
}

.faqsanser {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.81px;
  text-align: left;
  color: #545454;
  list-style: none;
  font-family: "Red Hat Display", sans-serif;
}
/* .card-main {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border: 0;
} */
/* .card-body {
  padding: 0px !important;
} */
.option-card {
  /* border: 2px solid #003754; */
  text-align: center;
  cursor: pointer;
  width: 220px;
  height: 241px;
  padding: 30px 20px 30px 20px;
  border-radius: 15px;
  /* opacity: 0px; */
  box-shadow: 10px 5px 30px 0px #083f8814;
}
.option-card.selected {
  border: 2px solid #003754;
}
.option-card-img {
  height: 80px;
  width: 80px;
  margin: 0 auto;
}
.option-card-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.option-card-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 23.81px;
  text-align: center;
  color: #010101;
  margin-top: 20px;
  margin-bottom: 0;
}

.option-card-subLine {
  font-size: 14px;
  font-weight: 400;
  line-height: 21.17px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
  color: #545454;
}
.course-list {
  margin-top: 20px;
}
.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  max-width: 420px;
  margin: 24px auto 0;
}
.contact-info {
  text-align: center;
  margin-top: 50px;
}

.title-main {
  margin: 0 auto 20px;
  max-width: 400px;
  height: 100px;
  margin-top: -20px;
  font-size: 26px;
  color: white;
  font-weight: 700;
  text-align: center;
  background-color: #003754;
  padding: 15px;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-line {
  font-size: 16px !important;
  color: rgb(57, 57, 57);
  text-align: center;
  font-weight: 400;
  max-width: 460px;
  margin: auto;
}

.tab-content-main {
  border: 1px solid #dddddd;
  border-radius: 12px;
}

.select-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.17px;
  text-align: left;
  color: #545454;
}

/* /////////////////////// */
/* box section  */

.cart-section {
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  padding: 16px;
  width: 500px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  background: #0037540d;
  margin: 0 auto;
  margin-top: 50px;
}

.cart-section h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 29.11px;
  color: #088206;
  margin-bottom: 30px;
}

.total-cost {
  padding-bottom: 10px;
  margin-bottom: 8px;
  color: #010101;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.81px;
  border-bottom: 1px dashed #dddddd;
}

.cart-section p {
  margin-bottom: 8px;
  color: #545454;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.81px;
  /* text-align: left; */
}

.cart-section strong {
  font-weight: bold;
}

.promo-section {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
}

.promo-section > input {
  flex: 1;
  margin-right: 8px;
  /* width: Fill (450px) px; */
  height: 64px !important;
  padding: 5px 5px 5px 20px;
  border-radius: 10px;
  border: 1px solid #dddddd;
}
.bsaket-img {
  width: 30px;
  height: 30px;
  position: relative;
}
.bsaket-img > img {
  width: 100%;
  object-fit: cover;
}
.red-dot {
  position: absolute;
  right: -5px;
  top: -11px;
}

.apply-btn {
  width: Hug (89px) px;
  height: Hug (44px) px;
  padding: 15px 20px 15px 20px;
  border-radius: 10px;
  background-color: #003754;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  outline: none;
  border: none;
  position: absolute;
  right: 14px;
  top: 7px;
}

.promo-section .el-button:hover {
  background-color: #004080;
}

/* /////////////////////// */
.unlock-course {
  background-color: #003754; /* Dark background color */
    color: #FFFFFF; /* White text color */
    font-weight: bold;
    padding: 12px 24px; /* Padding for the button-like appearance */
    border-radius: 12px; /* Rounded corners */
    text-align: center;
    display:block;
    width:fit-content;
    font-size: 18px; /* Adjust font size as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* margin-left: 119px; */
    margin: 10px auto
}
.unlock-course:hover {
  background-color: #004080; /* Slightly lighter on hover */
}
/* Custom Modal Container */
.header-title{
  color: #04060f;
  font-weight: 800;
  font-size: 20px;
}
.header-text{
  font-weight: 600;
  color: #747682;
  font-size: 16px;
}
.header-h3{
  font-weight: 700;
  color: #70747d;

}
.addons-container {
  border: 1px dashed #003754;
  border-radius: 8px;
  padding: 18px;
  max-width: 420px;
  margin: 0 auto;
}

.addons-title {
  background-color: #003754;
  color: white;
  padding: 10px;

  border-radius: 10px;
  margin: -20px -20px 20px -20px;
  text-align: left;
}

.addons-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.addon-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.addon-item:last-child {
  border-bottom: none;
}

.addon-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addon-info > .label {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 21.17px;
  text-align: start;
  color: #010101;
  margin-bottom: 0 !important;
}

.addon-icon {
  width: 24px;
  height: 24px;
}

.info-icon {
  width: 16px;
}
.info-icon > img {
  width: 100%;
  object-fit: cover;
}

/* ////// button css ///////////// */

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.btn-2 {
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 200px;
}

.btn-back {
  color: #000;
  border: 1px solid #ccc;
  background-color: #fff;
}

.btn-back:hover {
  color: #000;
  border: 1px solid #aaa;
  background-color: #f9f9f9;
}

.btn-signup {
  background-color: #c02e1c;
  color: #fff;
  border: none;
}

.btn-signup:hover {
  background-color: #a02518;
}

.icon {
  margin-right: 8px;
}

/* //////////////////////////////////////// */

.simpel-text {
  color: #545454;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.link-text {
  font-size: 14px;
  font-weight: 600;
  color: #00539b !important;
  text-align: center;
}
/* <!-- ///////////////////////////////////// --> */
.container {
  width: 80%;
  margin: 50px auto;
  background-color: #fff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border: 1px solid #dddddd;
  border-radius: 15px 15px 8px 8px;
  overflow: hidden;
}

.tabs {
  display: flex;
  /* background-color: #f1f1f1; */
  border: 2px solid #f1f1f1;
  box-shadow: 10px 5px 25px 0px #083f880d;
  padding: 3px;
  border-radius: 15px;
  width: 100%;
}

.tab-button {
  flex: 1;
  padding: 15px 20px;
  cursor: pointer;
  border: none;
  background-color: white;
  color: #333;
  text-align: center;
  transition: background-color 0.3s;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.17px;
  text-align: center;
  border-radius: 12px;
}

.tab-button.active {
  background-color: #003754;
  color: white;
}

.tab-button:hover {
  background-color: #e0e0e0;
}

.tab-content {
  padding: 0 15px;
  width: 100%;
  /* display: none; */
}

.tab-content.active {
  display: block;
}
.select-all > label {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.17px;
  color: #010101;
  margin-left: 10px;
}

.search-bar {
  width: 76% !important;
  position: relative;
}
.search-icon {
  position: absolute;
  right: 15px;
  top: 14px;
}

.search-bar > input {
  width: 100%;
  height: 54px;
  border-radius: 10px !important;
  /* opacity: 0px; */
  border: 1px solid #dddddd;
}

.search-bar input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.search-bar input[type="checkbox"] {
  margin-left: 10px;
}

.course-list > ul > li > label {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.17px;
  text-align: start;
  color: #545454;
}

.course-list h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.course-list ul {
  list-style: none;
  padding: 0;
}

.course-list ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.course-list ul li input[type="checkbox"] {
  margin-right: 10px;
}
.hr-2 {
  margin: 15px 0;
}
.bottom-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.17px;
  text-align: left;
  color: #545454;
}

.modal-dialog{
  max-width: 740px !important;
}
@media only screen and (max-width: 1500px) {
  .content {
    width: 100%;
    gap: 0;
  }
}
@media only screen and (max-width: 1300px) {
  .course-options {
    max-width: 650px;
  }
  /* .unlock-course{
    margin:10px auto;
   } */
}
@media only screen and (max-width: 1200px) {
  .course-options {
    border-right: none;
    border-bottom: 1px solid #dddddd;
    max-width: max-content;
  }
  .content {
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 820px) {
  .content-main {
    padding: 100px 14px 16px;
  }
}

@media only screen and (max-width: 620px) {
  .content-main {
    width: 100%;
  }
  .content,
  .summary-section {
    width: 100%;
  }
  .cart-section {
    width: 100%;
  }
  .container {
    width: 100%;
  }
  .title-main {
    max-width: 234px;
    height: 60px;
    margin-top: -20px;
    font-size: 22px;
  }
  .btn-2 {
    width: 140px;
  }
  .tabs {
    flex-wrap: wrap;
  }
  .search-bar {
    width: 100% !important;
  }
  .link-text {
    width: 70%;
    margin: 0 auto;
  }
  .error-message {
    color: #a02518;
  }
  .course-options {
    padding: 20px 20px;
  }

  .addon-info > .label {
    font-size: 12px !important;
  }
  .header-text,.header-h3{
  font-size: 14px;
}
}
</style>
<!-- import { hr } from "date-fns/locale";import { color } from "d3";import { color }
from "highcharts";import { color } from "highcharts"; -->
