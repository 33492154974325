<template>
  <div class="content content-main">
    <div class="container-fluid mt-3 mb-5">
      <card
        class="card no-border-card"
        footer-classes="pb-2"
        v-loading.fullscreen.lock="loading"
      >
        <div class="row">
          <div class="col-lg-6 col-md-12 mt-4" v-if="selectionType===''" style="margin: 0 auto; display: flex; flex-direction: column; align-items: center; min-height: 100vh; justify-content: center;">
            <div class="row">
              <div class="col-12">
                <div class="mx-3 text-center mobCard">
                  <i class="fas fa-user-alt user-icon"></i>
                  <h3>Individual User</h3>
                  <!-- <router-link to="/user_register" class="text-light"> -->
                    <base-button @click="selectTypeUser()">Click Here</base-button>
                  <!-- </router-link> -->
                  <!--
                    <button class="btn btn-default disabled-button" disabled>
                      (Coming Soon)
                    </button>
                  -->
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12">
                <div class="mx-3 text-center mobCard">
                  <i class="fas fa-users user-icon-company"></i>
                  <h3>Company</h3>
                  <!-- <router-link to="/register" class="text-light"> -->
                    <base-button @click="selectTypeCompany()">Click Here</base-button>
                  <!-- </router-link> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 mt-4" v-if="selectionType!==''" style="margin: 0 auto; display: flex; flex-direction: column; align-items: center; min-height: 100vh; justify-content: center;">
            <div class="row">
              <div class="col-12">
                <el-form-item label="Select Industry*" prop="industry">
                    <div>
                      <label for="stateSelect" class="form-label"
                        >Select Industury *</label
                      >
                      <el-select
                        id=""
                        class="mr-3 br-0"
                        style="width: 100%"
                        placeholder="Select Industry"
                        v-model="form.industry_id"
                        required
                      >
                        <el-option
                          v-for="(industry, index) in industuries"
                          class="select-dark"
                          :value="industry.id"
                          :label="industry.label"
                          :key="'industury' + index"
                        >
                        </el-option>
                      </el-select>
                    </div>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="px-xl-5 bg-white">
              <!-- Card header -->
              <div class="card-header">
                <div class="mb-4">
                  <img
                    class="image-fluid"
                    src="/Homeoftraining.png"
                    alt="home-of-training"
                  />
                </div>
                <p class="fs-14 font-weight-medium sub-line-p">
                    If you are a staff member of a company that uses our service, please
                    <a :href="'mailto:' + infoEmail"><u>click here</u></a> to email our
                    Support team, and they will add you to the system. For an individual
                    currently not working for a company we provide training to, click
                    Individual User. To sign up a company currently not using our
                    service, select Company. Thank you for your business!
                </p>
              </div>
              <!-- Card body -->
              <div class="card-header">
                <validation-observer
                  v-slot="{ handleSubmit }"
                  ref="formValidator"
                >
                  <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <el-row class="row gap-sm-2 marb-20">
                      <el-col class="col-12 col-md-6">
                        <el-form-item label="First Name*" prop="firstName">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="First name"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-single-02"
                            placeholder="First Name*"
                            v-model="form.firstName"
                            :disabled="form.industry_id==''"
                          >
                          </base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12 col-md-6">
                        <el-form-item label="Last Name*" prop="lastName">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Last name"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-single-02"
                            placeholder="Last Name*"
                            v-model="form.lastName"
                            :disabled="form.industry_id==''"
                          >
                          </base-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row class="row gap20 mb5">
                      <el-col class="col-12">
                        <el-form-item label="Email Address*" prop="email">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Email"
                            type="email"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-email-83"
                            placeholder="Email*"
                            v-model="form.email"
                            :disabled="form.industry_id==''"
                          >
                          </base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12 ">
                        <el-form-item label="Phone Number*" prop="phoneNumber">
                          <!-- :rules="{ required: true, pattern: /^[0-9]{10}$/ }" -->
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Phone number"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-mobile-button"
                            placeholder="Phone Number*"
                            v-model="form.phoneNumber"
                            @input="acceptNumber"
                            :disabled="form.industry_id==''"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12" v-if="selectionType!=='user'">
                        <el-form-item label="Company Name*" prop="companyName">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Company name"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-building"
                            placeholder="Company Name*"
                            v-model="form.companyName"
                            :disabled="form.industry_id==''"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12" v-if="selectionType!=='user'">
                        <el-form-item label="Company Name*" prop="companyName">
                          <base-input
                          type="text"
                          class="mb-3 login-input"
                          name="Website"
                          placeholder="Website"
                          v-model="form.website"
                            prepend-icon="ni ni-building"
                            :disabled="form.industry_id==''"
                        >
                        </base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12" v-if="selectionType!=='user'">
                        <form>
                          <file-input v-on:change="onImageChange"  :disabled="form.industry_id==''" ></file-input>
                        </form>
                      </el-col>

                      <el-col class="col-12 mb5" v-if="selectionType!=='user'">
                        <el-form-item label="Select Company Type*" prop="company_type">
                            <div>
                              <!-- <label for="stateSelect" class="form-label"
                                >Select State*</label
                              > -->
                              <el-select
                                id="stateSelect"
                                class="mr-3"
                                style="width: 100%"
                                placeholder="Select Company Type*"
                                v-model="form.company_type"
                                required
                                :disabled="form.industry_id==''"
                              >
                              <el-option
                              v-for="(option, index) in company_types"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="'company_type_' + index"
                            >
                            </el-option>
                              </el-select>
                              <!-- <span class="text-danger">
                                {{
                                  errors[0]
                                    ? "The Company type field is required"
                                    : ""
                                }}
                              </span> -->
                            </div>
                          <!-- </validation-provider> -->
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row class="row mb5" v-if="selectionType!=='user'">
                      <el-col class="col-6">
                        <el-form-item label="# of Locations*" prop="locations" >
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Locations"
                            type="number"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-address-83"
                            placeholder="# of Locations*"
                            min="0"
                            v-model="form.locations"
                            :disabled="form.industry_id==''"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-6">
                        <el-form-item
                          label="Estimated # of Users per Location*"
                          prop="users"
                        >
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Users"
                            type="number"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-address-83"
                            placeholder="Estimated # of Users per Location*"
                            v-model="form.users"
                            min="0"
                            :disabled="form.industry_id==''"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row class="row mb5" >
                      <el-col class="col-6">
                        <el-form-item label="Address*" prop="address">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Address"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-pin-3"
                            placeholder="Address*"
                            v-model="form.address"
                            :disabled="form.industry_id==''"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-6">
                        <el-form-item label="State*" prop="state">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="state"
                            :rules="{ required: true }"
                            placeholder="State*"
                            v-model="form.state"
                            :disabled="form.industry_id==''"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row class="row mb5">
                      <el-col class="col-6">
                        <el-form-item label="City*" prop="city">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="City"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-pin-3"
                            placeholder="City*"
                            v-model="form.city"
                            :disabled="form.industry_id==''"

                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-6">
                        <el-form-item label="Zip*" prop="zip">
                          <base-input
                            type="number"
                            alternative
                            class="mb-3 login-input"
                            name="zip"
                            :rules="{ required: true }"
                            placeholder="Zip*"
                            v-model="form.zip"
                            :disabled="form.industry_id==''"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <div class="w-100 mb-4">
                      <button type="primary" class="w-100 next-btn text-white">
                        <!-- @click.prevent="handleSubmit" -->
                        Next
                        <span>
                          <svg
                            width="19"
                            height="13"
                            viewBox="0 0 19 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m18.148 7.32-4.5 4.5a1.084 1.084 0 0 1-1.582 0 1.084 1.084 0 0 1 0-1.581l2.567-2.602H1.625A1.11 1.11 0 0 1 .5 6.512c0-.597.492-1.125 1.125-1.125h13.008l-2.567-2.566a1.084 1.084 0 0 1 0-1.582 1.084 1.084 0 0 1 1.582 0l4.5 4.5c.457.422.457 1.16 0 1.582"
                              fill="#fff"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </form>
                  <p class="error" v-if="errors.invalid">
                    {{ errors.invalid }}
                  </p>
                </validation-observer>
                <div class="contact-info">
                  <p class="simpel-text mb-0">
                    If you have any questions, please contact us at
                  </p>
                  <p class="link-text">
                    <span class="mr-1">
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5.5h13c.813 0 1.5.688 1.5 1.5 0 .5-.25.938-.625 1.219L8.594 8.313a.986.986 0 0 1-1.219 0L.594 3.218A1.5 1.5 0 0 1 0 2 1.5 1.5 0 0 1 1.5.5M0 4l6.781 5.125a2.02 2.02 0 0 0 2.407 0L16 4v6.5c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2z"
                          fill="#00539B"
                        />
                      </svg>
                    </span>
                    <a
                      class="link-text mr-3"
                      href="mailto:support@homeoftraining.com"
                      >support@homeoftraining.com</a
                    >
                    |                   <span>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m5.125 1.281 1.25 3c.219.5.094 1.094-.344 1.438L4.5 7a10.5 10.5 0 0 0 5 5l1.281-1.531a1.206 1.206 0 0 1 1.438-.344l3 1.25c.594.219.875.875.719 1.469l-.75 2.75A1.24 1.24 0 0 1 14 16.5c-7.75 0-14-6.25-14-14 0-.562.375-1.031.906-1.187l2.75-.75c.594-.157 1.25.125 1.469.718"
                        fill="#00539B"
                      />
                    </svg>
                  </span>
                  954-676-7900
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Row } from "element-ui";
import FileInput from "@/components/Inputs/FileInput";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "HospitalityInsurance",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [Row.name]: Row,
    [TableColumn.name]: TableColumn,
    FileInput,

  },
  data() {
    return {
      company_types: [],
      loading: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        address: "",
        locations: "",
        users: "",
        city:"",
        zip:"",
        state:"",
        industry_id:"",
        company_type:"",
        website:"",
      },
      image: "",
      industuries :[
        {id: '1', label: 'Industry 1'},
        {id: '2', label: 'Industry 2'},
        {id: '3', label: 'Industry 3'},
      ],
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input first name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please input last name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: "Please input valid email",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: "Please input phone number",
            trigger: "blur",
          },
        ],
        companyName: [
          {
            required: true,
            message: "Please input company name",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "Please input address", trigger: "blur" },
        ],
        state_id: [
          { required: true, message: "Please select state", trigger: "blur" },
        ],
        locations: [
          {
            required: true,
            type: "number",
            message: "Please input number of locations",
            trigger: "blur",
          },
        ],
        users: [
          {
            required: true,
            type: "number",
            message: "Please input number of users",
            trigger: "blur",
          },
        ],
      },
      errors: {
        invalid: "",
      },
      selectionType:""
    };
  },
  created() {
    this.$http.post("company/company_dropdown_data", {}).then(resp => {
            for (let type of resp.data.companytype) {
                let obj = {
                    label: type.type,
                    value: type.id
                };
                this.company_types.push(obj);
            }
        });
    let insurancePayload = JSON.parse(
      localStorage.getItem("hospitality-insurance")
    );
    if (insurancePayload) {
      this.form.firstName = insurancePayload.first_name;
      this.form.lastName = insurancePayload.last_name;
      this.form.email = insurancePayload.email;
      this.form.phoneNumber = insurancePayload.phone_num;
      this.form.companyName = insurancePayload.company_name;
      this.form.state_id = insurancePayload.state_id;
      this.form.address = insurancePayload.address;
      this.form.locations = insurancePayload.number_of_locations;
      this.form.users = insurancePayload.number_of_employees;
    }
  },
  methods: {

    onImageChange(e) {
            let files = e;
            if (!files.length) return;
            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = e => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },

    selectTypeUser()
    {
      this.selectionType="user";
      localStorage.removeItem("hospitality-insurance");

    },
    selectTypeCompany()
    {
      this.selectionType="company";
      this.$http.post("company/company_dropdown_data", {}).then(resp => {
            for (let type of resp.data.companytype) {
                let obj = {
                    label: type.type,
                    value: type.id
                };
                this.company_types.push(obj);
            }
        });
    },
    openLightBoxPopup() {
      this.showLighBoxModal = true;
    },

    acceptNumber() {
      var x = this.form.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    onSubmit() {
      // Firstly remove exsisting from local storage
      localStorage.removeItem("hospitality-insurance");
      // now if user is individual
     if (this.selectionType == "user") {
      let data = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        user_type: "individual",
        state: this.form.state,
        zip_code: this.form.zip,
        city:this.form.city,
        email: this.form.email,
        phone_num: this.form.phoneNumber,
        address: this.form.address,
        i_agree: true,
        number_of_locations: 1,
        number_of_employees:1,
        industry: this.form.industry_id,
        type: this.selectionType,
      };

    localStorage.setItem("hospitality-insurance", JSON.stringify(data));
      }
      // now if user is of company
     else if (this.selectionType == "company") {
      let data = {
      first_name: this.form.firstName,
      last_name: this.form.lastName,
      email: this.form.email,
      phone_num: this.form.phoneNumber,
      company_name: this.form.companyName,
      address: this.form.address,
      state: this.form.state,
      number_of_locations: this.form.locations,
      number_of_employees: this.form.users,
      city: this.form.city,
      zip_code: this.form.zip,
      company_type : this.form.company_type,
      username : this.form.email ,
      website: this.form.website,
      i_agree: true,
      image : this.image,
      industry: this.form.industry_id,
      type: this.selectionType,

    };

    localStorage.setItem("hospitality-insurance", JSON.stringify(data));
  }

  this.$router.push(`/new-price`);
},

  },
};
</script>

<style>
.select-primary .el-select {
  border-radius: 4px; /* Match with base-input border radius */
  height: 40px; /* Adjust height to match */
  padding: 8px; /* Adjust padding if needed */
}
.form-section {
  background-color: transparent;
}
.login-section {
  background-color: #ececf9;
  padding: 0px;
}
.course-section {
  padding: 40px;
}
.error {
  color: red;
  text-align: center;
}
.py-5 {
  padding-bottom: 0px !important;
}
.mt--10 {
  margin-top: -10rem !important;
}
.disabled-button {
  transform: none;
}
.user-icon,
.user-icon-company {
  color: #80d610;
}
.btn-default {
  background-color: #80d610;
  border-color: #80d610;
}
.content-main {
  padding: 100px 50px 50px;
}
.container {
  display: flex;
  flex-wrap: wrap;
}
.image-section {
  flex: 1;
  /* background: url('@/assets/your-image-path.jpg') no-repeat center center; */
  background-size: cover;
}
.left-side-main {
  overflow: hidden;
  border-radius: 0 100px 100px 0;
  position: relative;
  width: 100%;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.left-side-main::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #003754;
  opacity: 60%;
  z-index: 1;
}
.left-side-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0 100px 100px 0;
  position: relative;
}
.left-side-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.dots-svg {
  position: absolute;
  left: 0;
  top: 7%;
  width: 160px;
  z-index: 1;
}
.dots-svg > img {
  width: 100%;
  object-fit: cover;
}
.bottom-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 7%;
  width: 70%;
  margin: 0 auto;
  z-index: 1;
}
.bottom-content > h1 {
  color: white;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
}
.bottom-content > p {
  color: white;
  font-size: 22px;
  font-weight: 400;
  line-height: 39.69px;
  text-align: left;
}
.logo-img {
  width: 280px;
  overflow: hidden;
  border-radius: 0 100px 100px 0;
}
.mb5{
  margin-bottom: 20px
}
.logo-img img {
  width: 100%;
  object-fit: cover;
  /* height: 100%; */
}
.form-section {
  flex: 1;
  padding: 20px;
}
h1 {
  font-size: 24px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  margin-bottom: 20px;
}
.contact-info {
  margin-top: 20px;
  font-size: 14px;
}
.contact-info a {
  color: #007bff;
}
.next-btn {
  background-color: #c02e1c;
  border: none;
  outline: none;
  height: Fixed (54px) px;
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.81px;
  text-align: left;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.simpel-text {
  color: #545454;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.unlock-course {
  background-color: #003754; /* Dark background color */
    color: #FFFFFF; /* White text color */
    font-weight: bold;
    padding: 12px 24px; /* Padding for the button-like appearance */
    border-radius: 12px; /* Rounded corners */
    text-align: center;
    display: inline-block;
    font-size: 14px; /* Adjust font size as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.unlock-course:hover {
  background-color: #004080; /* Slightly lighter on hover */
}

.link-text {
  font-size: 14px;
  font-weight: 600;
  color: #00539b !important;
  text-align: center;
}

.sub-line-p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.81px;
  text-align: left;
  color: #545454;
}
.el-input > input {
  border: 1px solid #dddddd;
  height: 54px;
  padding: 15px 20px 15px 20px;
  border-radius: 10px;
}
.card > .card-body {
  padding: 0;
}
.el-input {
  position: relative;
}
.el-input__prefix {
  position: absolute;
  left: 92%;
  top: 14px;
}
.input-icon-first > .el-input__prefix {
  position: absolute;
  left: 85%;
  top: 14px;
}
.el-input--prefix .el-input__inner {
  padding-left: 20px;
}
.gap-2 {
  gap: 24px;
}
.text-danger {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}
.marb-20{
  margin-bottom: 20px;
}
.gap20{
  row-gap: 20px;
}
/* Custom Modal Container */
.header-title{
  color: #04060f;
  font-weight: 800;
  font-size: 20px;
}
.header-text{
  font-weight: 600;
  color: #747682;
  font-size: 16px;
}
.header-h3{
  font-weight: 700;
  color: #70747d;

}

@media only screen and (max-width: 820px) {
  .content-main {
    padding: 100px 14px 16px;
  }

  .left-side-main {
    border-radius: 0 !important;
  }
  .left-side-img {
    border-radius: 0 !important;
  }

  .bottom-content {
    width: 92%;
  }
}
@media only screen and (max-width: 620px) {
  .gap-sm-2 {
    gap: 16px;
  }
  .bottom-content {
    width: 92%;
  }
  .bottom-content > h1 {
    color: white;
    font-size: 22px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
  }
  .bottom-content > p {
    font-size: 16px;
    line-height: 22px;
  }
  .dots-svg {
    width: 96px;
  }
  .marb-20{
  margin-bottom: 0px;
}
.header-text,.header-h3{
  font-size: 14px;
}

}
</style>
