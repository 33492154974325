<template>
  <card>
      <div class="mt-2">
          <form role="form" class="">
          <div class="row">
        <div class="col-4" >
          <input
            type="radio"
            name="paymenttype"
            value="monthly"
            v-model="card.paymentType"
          />
          Monthly
        </div>
        <div class="col-4 " >
          <input
            type="radio"
            value="yearly"
            name="paymenttype"
            v-model="card.paymentType"
          />
          Yearly
        </div>

      </div>
      <div class="col-12 mb-2">
          Amount Payable:
          <span
            v-if="
              card.paymentType == 'monthly' &&
                monthlyAmount
            "
            >{{ formatPrice(monthlyAmount) }}</span
          >
          <span v-if="card.paymentType == 'yearly' && yearlyAmount"
            >{{ formatPrice(yearlyAmount) }}
            <span >(10% Off)</span></span
          >
        </div>
              <span>
                  <stripe-element-card
                   :pk="publicKey"
                   ref="elementRef"
                   :hidePostalCode="postalCode"
                   @token="tokenCreated"
                   @error="errorHandling"
                   />
              </span>
              <base-input type="text" name="Address" label="Billing Address *" placeholder="Address" rules="required" v-model="address"></base-input>
              <div class="row">
                <div class="col-md-5 col-8">
                  <base-input
                    type="text"
                    name="City"
                    label="City *"
                    placeholder="City"
                    rules="required"
                    v-model="city"
                  >
                  </base-input>
                </div>

                <div class="col-md-3 col-8">
                  <base-input
                    type="text"
                    name="State"
                    label="State *"
                    placeholder="State"
                    rules="required"
                    v-model="state"
                  >
                  </base-input>
                </div>

                <div class="col-md-4 col-8">
                  <base-input
                    type="number"
                    label="Zip Code *"
                    name="Zip code"
                    placeholder="Zip"
                    rules="required"
                    v-model="zip"
                  >
                  </base-input>
                </div>
              </div>
              <base-button class="custom-btn" type="button" @click.prevent="paymentClicked" block :disabled="enablePaymentButton">Pay & Create Account</base-button>
              <!-- :disabled="isPaymentButtonDisabled" -->
          </form>
      </div>
      <div class="row mt-4 justify-content-between align-items-left">
          <div class="col-md-6 text-left">
              <img width="100px" src="img/icons/cards/pci-dss-logo.png" alt="Image placeholder"/>
          </div>
          <div class="col-md-6 text-right">
              <img width="150px" src="img/icons/cards/credit-card.jpg" alt="Image placeholder"/>
          </div>
      </div>
  </card>
</template>
<script>
import {StripeElementCard} from "@vue-stripe/vue-stripe";
import { Table, TableColumn, Select, Option, Row } from "element-ui";
//Vue.use(VueCardFormat);
export default {
  name: "master-card",
  //props: ["monthlyAmount",],
  components: {
   StripeElementCard,
  // [StripeElementCard.name]: StripeElementCard,
  [Select.name]: Select,
  [Option.name]: Option,
  [Table.name]: Table,
  [Row.name]: Row,
  [TableColumn.name]: TableColumn
  },
  props: {
      companyName: String,
      address: String,
      state: String,
      city: String,
      zip: Number,
      // amount: Number,
      monthlyAmount: Number,
      yearlyAmount: Number,
      enablePaymentButton: Boolean,
  },
  mounted() {
      // this.getStatesList();
  },
  data() {
      return {
          // states: [],
          card: {
              cardNumber: "",
              expire: "",
              address: this.address,
              state: this.state,
              paymentType: 'monthly',
              transcationAmount: "",
              // companyName: this.companyName,
              city: this.city,
              zip: this.zip,
             token: null
          },
          publicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
          postalCode: true,
      };
  },
  methods: {
      formatPrice(value) {
          return "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      },
      getStatesList() {
    this.$http
      .get("/states")
      .then((resp) => {
        this.states = resp.data.states;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
      tokenCreated(token) {
        console.log('Token created:', token); // Debugging line
          this.card.token = {
           id: token.id
          };
          // this.card.zip = this.zip;
          this.card.address = this.address;
          this.card.transcationAmount =  this.card.paymentType == 'monthly' ? this.monthlyAmount : this.yearlyAmount
          this.card.state = this.state;
          this.card.city = this.city;
          this.$emit("payClicked", this.card);
      },
      paymentClicked() {
          this.enablePaymentButton = true;
          // this.$emit("payClicked", this.card);
          this.$refs.elementRef.submit();

      },
      errorHandling: function (errors) {
          this.enablePaymentButton = false;
      },
  },
};
</script>
<style scoped>
.bg-gradient-primary {
  background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}
.custom-btn{
color: #ffffff;
}
</style>
