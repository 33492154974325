<template>
  <div class="content content-main">
    <div class="container-fluid mt-3 mb-5">
      <card
        class="card no-border-card"
        footer-classes="pb-2"
        v-loading.fullscreen.lock="loading"
      >
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="left-side-main">
              <div class="dots-svg">
                <img src="../../assets/dots.svg" alt="dots-svg" />
              </div>
              <div class="left-side-img">
                <img
                  src="../../assets/cources-img.jpg"
                  alt="Hospitality Insurance Group"
                />
              </div>
              <div class="bottom-content">
                <!-- <h1>Hospitality Insurance Group</h1> -->
                <div class="logo-img mb-2">
                  <img
                    src="/signup-img/hig.png"
                    alt="Hospitality Insurance Group"
                    class="blend-image"
                  />
                </div>
                <p>Taking the Risk Out of Hospitality!</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="py-5   px-xl-5 bg-white">
              <!-- Card header -->
              <div class="card-header">
                <img
                    src="/signup-img/hot.svg"
                    alt="Home of Training"
                     class="img-fluid"
                     width="200px"
                  />
                <div class=" mb-4">
                </div>
                <p class="fs-14 font-weight-medium sub-line-p">
                  Hospitality Insurance Group is excited to announce that we have partnered with Home of Training to provide you with their online training classes to help you meet your compliance needs and save you money on your insurance. To get an estimate of services, please provide your company information below.
                </p>

                <p class="unlock-course pointer" @click="openLightBoxPopup()">Unlock Significant Savings with Our Online
                  Courses</p>
              </div>
              <!-- Card body -->
              <div class="card-header">
                <validation-observer
                  v-slot="{ handleSubmit }"
                  ref="formValidator"
                >
                  <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <el-row class="row gap-sm-2 marb-20">
                      <el-col class="col-12 col-md-6">
                        <el-form-item label="First Name*" prop="firstName">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="First name"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-single-02"
                            placeholder="First Name*"
                            v-model="form.firstName"
                          >
                          </base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12 col-md-6">
                        <el-form-item label="Last Name*" prop="lastName">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Last name"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-single-02"
                            placeholder="Last Name*"
                            v-model="form.lastName"
                          >
                          </base-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row class="row gap20 mb5">
                      <el-col class="col-12">
                        <el-form-item label="Email Address*" prop="email">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Email"
                            type="email"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-email-83"
                            placeholder="Email*"
                            v-model="form.email"
                          >
                          </base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12 ">
                        <el-form-item label="Phone Number*" prop="phoneNumber">
                          <!-- :rules="{ required: true, pattern: /^[0-9]{10}$/ }" -->
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Phone number"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-mobile-button"
                            placeholder="Phone Number*"
                            v-model="form.phoneNumber"
                            @input="acceptNumber"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12">
                        <el-form-item label="Company Name*" prop="companyName">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Company name"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-building"
                            placeholder="Company Name*"
                            v-model="form.companyName"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12">
                        <el-form-item label="Address*" prop="address">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Address"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-pin-3"
                            placeholder="Address*"
                            v-model="form.address"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-12 mb5">
                        <el-form-item label="Select State*" prop="state">
                          <validation-provider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div>
                              <!-- <label for="stateSelect" class="form-label"
                                >Select State*</label
                              > -->
                              <el-select
                                id="stateSelect"
                                class="mr-3"
                                style="width: 100%"
                                placeholder="Select State*"
                                v-model="form.state_id"
                                required
                              >
                                <el-option
                                  v-for="(state, index) in states"
                                  class="select-primary"
                                  :value="state.id"
                                  :label="state.name"
                                  :key="'state' + index"
                                >
                                </el-option>
                              </el-select>
                              <span class="text-danger">
                                {{
                                  errors[0]
                                    ? "The State name field is required"
                                    : ""
                                }}
                              </span>
                            </div>
                          </validation-provider>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row class="row mb5">
                      <el-col class="col-6">
                        <el-form-item label="# of Locations*" prop="locations">
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Locations"
                            type="number"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-address-83"
                            placeholder="# of Locations*"
                            min="0"
                            v-model="form.locations"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                      <el-col class="col-6">
                        <el-form-item
                          label="Estimated # of Users per Location*"
                          prop="users"
                        >
                          <base-input
                            alternative
                            class="mb-3 login-input"
                            name="Users"
                            type="number"
                            :rules="{ required: true }"
                            prepend-icon="ni ni-address-83"
                            placeholder="Estimated # of Users per Location*"
                            v-model="form.users"
                            min="0"
                          ></base-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <div class="w-100">
                      <button type="primary" class="w-100 next-btn text-white">
                        <!-- @click.prevent="handleSubmit" -->
                        Next
                        <span>
                          <svg
                            width="19"
                            height="13"
                            viewBox="0 0 19 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m18.148 7.32-4.5 4.5a1.084 1.084 0 0 1-1.582 0 1.084 1.084 0 0 1 0-1.581l2.567-2.602H1.625A1.11 1.11 0 0 1 .5 6.512c0-.597.492-1.125 1.125-1.125h13.008l-2.567-2.566a1.084 1.084 0 0 1 0-1.582 1.084 1.084 0 0 1 1.582 0l4.5 4.5c.457.422.457 1.16 0 1.582"
                              fill="#fff"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </form>
                  <p class="error" v-if="errors.invalid">
                    {{ errors.invalid }}
                  </p>
                </validation-observer>
                <div class="contact-info">
                  <p class="simpel-text mb-0">
                    If you have any questions, please contact us at
                  </p>
                  <p class="link-text">
                    <span class="mr-1">
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5.5h13c.813 0 1.5.688 1.5 1.5 0 .5-.25.938-.625 1.219L8.594 8.313a.986.986 0 0 1-1.219 0L.594 3.218A1.5 1.5 0 0 1 0 2 1.5 1.5 0 0 1 1.5.5M0 4l6.781 5.125a2.02 2.02 0 0 0 2.407 0L16 4v6.5c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2z"
                          fill="#00539B"
                        />
                      </svg>
                    </span>
                    <a
                      class="link-text mr-3"
                      href="mailto:info@homeoftraining.com"
                      >info@homeoftraining.com</a
                    >
                    |                   <span>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m5.125 1.281 1.25 3c.219.5.094 1.094-.344 1.438L4.5 7a10.5 10.5 0 0 0 5 5l1.281-1.531a1.206 1.206 0 0 1 1.438-.344l3 1.25c.594.219.875.875.719 1.469l-.75 2.75A1.24 1.24 0 0 1 14 16.5c-7.75 0-14-6.25-14-14 0-.562.375-1.031.906-1.187l2.75-.75c.594-.157 1.25.125 1.469.718"
                        fill="#00539B"
                      />
                    </svg>
                  </span>
                  954-676-7900
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
     <!-- showLighBoxModal Modal -->
     <modal :show.sync="showLighBoxModal" >
        <h2 slot="header" class="header-title text-ml font-bold">
          Unlock Significant Savings with Our Online
Courses
        </h2>
        <p class="header-text">

        Complete all assigned courses and enjoy up to a 10% discount on your insurance
        premium. That's not all - you'll also benefit from reduced accidents, lower employee
        turnover, and streamlined training processes.</p>
        <h3 class="header-h3">
          See the Proof: One of Home of Training’s clients, a national restaurant chain with 400+ locations, reduced their dramshop liability claims by 95% within a year after implementing a training program. This significant decrease translated directly into substantial cost savings and improved legal compliance.
        </h3>
      </modal>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Row } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  name: "HospitalityInsurance",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [Row.name]: Row,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        companyName: "",
        address: "",
        state_id: null,
        locations: "",
        user: "",
      },
      states: [],
      showLighBoxModal: false,
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input first name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please input last name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: "Please input valid email",
            trigger: "blur",
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: "Please input phone number",
            trigger: "blur",
          },
        ],
        companyName: [
          {
            required: true,
            message: "Please input company name",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "Please input address", trigger: "blur" },
        ],
        state_id: [
          { required: true, message: "Please select state", trigger: "blur" },
        ],
        locations: [
          {
            required: true,
            type: "number",
            message: "Please input number of locations",
            trigger: "blur",
          },
        ],
        users: [
          {
            required: true,
            type: "number",
            message: "Please input number of users",
            trigger: "blur",
          },
        ],
      },
      errors: {
        invalid: "",
      },
    };
  },
  created() {
    this.getStatesList();

    let insurancePayload = JSON.parse(
      localStorage.getItem("hospitality-insurance")
    );
    if (insurancePayload) {
      this.form.firstName = insurancePayload.first_name;
      this.form.lastName = insurancePayload.last_name;
      this.form.email = insurancePayload.email;
      this.form.phoneNumber = insurancePayload.phone_num;
      this.form.companyName = insurancePayload.company_name;
      this.form.state_id = insurancePayload.state_id;
      this.form.address = insurancePayload.address;
      this.form.locations = insurancePayload.number_of_locations;
      this.form.users = insurancePayload.number_of_employees;
    }
  },
  methods: {

    openLightBoxPopup() {
      this.showLighBoxModal = true;
    },
     getStatesList() {
      this.$http
        .get("/states")
        .then((resp) => {
          this.states = resp.data.states;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    acceptNumber() {
      var x = this.form.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    onSubmit() {
      let data = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        email: this.form.email,
        phone_num: this.form.phoneNumber,
        company_name: this.form.companyName,
        address: this.form.address, // to be included
        state_id: this.form.state_id, // to be included
        number_of_locations: this.form.locations,
        number_of_employees: this.form.users,
      };
      localStorage.setItem("hospitality-insurance", JSON.stringify(data));
      const companyName = this.$route.params.companyName;
      if (
        this.$route.path !== `/hig/${companyName}/course_selection`
      ) {
        this.$router.push(`/hig/${companyName}/course_selection`);
      }

      // return
      //       this.$http
      //         .post("company/register", data)
      //         .then(resp => {
      //           console.log("resp",resp)
      //             Swal.fire({
      //                 title: "Success!",
      //                 text: `Account has been Created!`,
      //                 icon: "success"
      //               });
      // localStorage.removeItem('hospitality-insurance')
      //         })
      //         .catch(function(error) {
      //           if (error.response.status === 422) {
      //             Swal.fire({
      //               title: "Error!",
      //               text: error.response.data.message,
      //               icon: "error"
      //             });
      //           }
      //         })
      //         .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
.content-main {
  padding: 100px 50px 50px;
}
.container {
  display: flex;
  flex-wrap: wrap;
}
.image-section {
  flex: 1;
  /* background: url('@/assets/your-image-path.jpg') no-repeat center center; */
  background-size: cover;
}
.left-side-main {
  overflow: hidden;
  border-radius: 0 100px 100px 0;
  position: relative;
  width: 100%;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.left-side-main::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #003754;
  opacity: 60%;
  z-index: 1;
}
.left-side-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0 100px 100px 0;
  position: relative;
}
.left-side-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.dots-svg {
  position: absolute;
  left: 0;
  top: 7%;
  width: 160px;
  z-index: 1;
}
.dots-svg > img {
  width: 100%;
  object-fit: cover;
}
.bottom-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 7%;
  width: 90%;
  margin: 0 auto;
  z-index: 1;
}
.bottom-content > h1 {
  color: white;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
}
.bottom-content > p {
  color: white;
  font-size: 22px;
  font-weight: 400;
  line-height: 39.69px;
  text-align: left;
}
.logo-img {
  width: 280px;
  overflow: hidden;
  border-radius: 0 100px 100px 0;
}
.mb5{
  margin-bottom: 20px
}
.logo-img img {
  width: 100%;
    
    object-fit: cover;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  /* height: 100%; */
}
.form-section {
  flex: 1;
  padding: 20px;
}
h1 {
  font-size: 24px;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  margin-bottom: 20px;
}
.contact-info {
  margin-top: 20px;
  font-size: 14px;
}
.contact-info a {
  color: #007bff;
}
.next-btn {
  background-color: #c02e1c;
  border: none;
  outline: none;
  height: Fixed (54px) px;
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.81px;
  text-align: left;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.simpel-text {
  color: #545454;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.unlock-course {
  background-color: #003754; /* Dark background color */
    color: #FFFFFF; /* White text color */
    font-weight: bold;
    padding: 12px 24px; /* Padding for the button-like appearance */
    border-radius: 12px; /* Rounded corners */
    text-align: center;
    display: inline-block;
    font-size: 14px; /* Adjust font size as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.unlock-course:hover {
  background-color: #004080; /* Slightly lighter on hover */
}

.link-text {
  font-size: 14px;
  font-weight: 600;
  color: #00539b !important;
  text-align: center;
}

.sub-line-p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.81px;
  text-align: left;
  color: #545454;
}
.el-input > input {
  border: 1px solid #dddddd;
  height: 54px;
  padding: 15px 20px 15px 20px;
  border-radius: 10px;
}
.card > .card-body {
  padding: 0;
}
.el-input {
  position: relative;
}
.el-input__prefix {
  position: absolute;
  left: 92%;
  top: 14px;
}
.input-icon-first > .el-input__prefix {
  position: absolute;
  left: 85%;
  top: 14px;
}
.el-input--prefix .el-input__inner {
  padding-left: 20px;
}
.gap-2 {
  gap: 24px;
}
.text-danger {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}
.marb-20{
  margin-bottom: 20px;
}
.gap20{
  row-gap: 20px;
}
/* Custom Modal Container */
.header-title{
  color: #04060f;
  font-weight: 800;
  font-size: 20px;
}
.header-text{
  font-weight: 600;
  color: #747682;
  font-size: 16px;
}
.header-h3{
  font-weight: 700;
  color: #70747d;

}

@media only screen and (max-width: 820px) {
  .content-main {
    padding: 100px 14px 16px;
  }

  .left-side-main {
    border-radius: 0 !important;
  }
  .left-side-img {
    border-radius: 0 !important;
  }

  .bottom-content {
    width: 92%;
  }
}
@media only screen and (max-width: 620px) {
  .gap-sm-2 {
    gap: 16px;
  }
  .bottom-content {
    width: 92%;
  }
  .bottom-content > h1 {
    color: white;
    font-size: 22px;
    font-weight: 700;
    line-height: 42px;
    text-align: left;
  }
  .bottom-content > p {
    font-size: 16px;
    line-height: 22px;
  }
  .dots-svg {
    width: 96px;
  }
  .marb-20{
  margin-bottom: 0px;
}
.header-text,.header-h3{
  font-size: 14px;
}

}
</style>
